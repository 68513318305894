<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-6 shape-left inheader-not-transparent height-500">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title color-white" data-aos="fade-up" data-aos-delay="100">
															网络安全领军者
                            </h1>
                            <p class="description color-white" data-aos="fade-up" data-aos-delay="150">
															技术过硬、创新务实，<br>坚持通过自主创新，努力提升公司核心竞争力
                            </p>
                        </div>
                    </div>
                    <div class="shape-image w-auto">
                        <img src="../assets/images/white-shape.svg" alt="Banner Images"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
							<div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="驰安大数据防护系统"
                            data-aos="fade-up"
                        />
                    </div>
										<div class="col-lg-12">
											<div class="description" data-aos="fade-up">
												驰安大数据防护系统是针对大数据资源库，基于数据库协议分析与控制技术的安全防护系统。系统利用主动防御机制，实现大数据资源库的访问行为控制、危险操作阻断、可疑行为审计。
											</div>
										</div>
                </div>
							<div class="row row--15">
								<div class="col-lg-6 col-md-6 col-sm-6 col-12">
									<div class="service service__style--2 icon-circle-style text-center"
											 data-aos="fade-up"
											 data-aos-delay="70">
										<div class="image">
											<img src="../assets/images/_new/solution1.png">
										</div>
										<div class="content">
											<h4 class="title w-600">
												数据泄漏防护解决方案
											</h4>
										</div>
									</div>
								</div>
								<div class="col-lg-6 col-md-6 col-sm-6 col-12">
									<div class="service service__style--2 text-center"
											 data-aos="fade-up"
											 data-aos-delay="70">
										<div class="image">
											<img src="../assets/images/_new/solution2.png">
										</div>
										<div class="content">
											<h4 class="title w-600">
												数据库安全综合解决方案
											</h4>
										</div>
									</div>
								</div>
							</div>
            </div>
        </div>

				<div class="rn-service-area rn-section-gap bg-light">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="驰安安全验证平台"
									data-aos="fade-up"
								/>
							</div>
							<div class="col-lg-12">
								<div class="description" data-aos="fade-up">
									驰安安全验证平台通过自动化的闭环手段、最新最全的验证用例，持续对企业纵深防御体系的有效性进行验证，并提供高度可见的安全度量，帮助企业提高运营效率、发现和解决安全失效点问题、全面提升安全运营能力。
								</div>
							</div>
						</div>
						<div class="row row--15 service-wrapper">
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op1.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													安全自动化巡检
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op2.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													精细化安全策略运营
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op3.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													企业防护能力检验
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="rn-service-area rn-section-gap">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="驰安DDoS高防御IP防御服务系统"
									data-aos="fade-up"
								/>
							</div>
							<div class="col-lg-12">
								<div class="description" data-aos="fade-up">
									针对互联网服务器（包括公有云、IDC主机）在遭受大流量DDOS攻击后导致服务不可用的情况下，推出的付费服务，用户可通过配置高防IP，将攻击流量引流到高防IP，确保源站的稳定可靠性。
								</div>
							</div>
						</div>
						<div class="row row--15 service-wrapper">
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud1.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													智慧城市
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud2.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													电商平台
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud3.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													互联网金融
												</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle.vue'
    export default {
        name: 'Home',
        components: {
            SectionTitle,
            Layout
        },
        data() {
            return {
            }
        }
    }
</script>
<style lang="scss">
.description {
	font-size: 20px;
	margin-top: 20px;
	.description-line {
		margin-top: 12px;
		text-indent: 2em;
	}
}
.size40 {
	width: 40px;
	height: 40px;
}
@media (min-width: 1200px) {
	.container-xl, .container-lg, .container-md, .container-sm, .container {
		max-width: 1100px !important;
	}
}
.content {
	.description {
		text-align: justify;
	}
}
</style>
