<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-6 shape-left inheader-not-transparent height-500">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title color-white" data-aos="fade-up" data-aos-delay="100">
															数据安全防护
                            </h1>
                            <p class="description color-white" data-aos="fade-up" data-aos-delay="150">
															在终端、服务器端、云虚拟环境、网络端、邮件端支持全覆盖多点部署，<br>为企业提供数据安全保障。
                            </p>
                        </div>
                    </div>
                    <div class="shape-image w-auto">
                        <img src="../assets/images/white-shape.svg" alt="Banner Images"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
							<div class="row row--30 align-items-center">
								<div class="col-lg-7 mt_md--40 mt_sm--40">
									<div class="content">
										<div class="section-title">
											<h2 class="title mt--10" data-aos="fade-up" data-aos-delay="310">驰安大数据防护系统</h2>
											<p data-aos="fade-up" data-aos-delay="310">
												<div class="description" data-aos="fade-up">
													驰安大数据防护系统是针对大数据资源库，基于数据库协议分析与控制技术的安全防护系统。系统利用主动防御机制，实现大数据资源库的访问行为控制、危险操作阻断、可疑行为审计。
												</div>
											</p>
										</div>
									</div>
								</div>
								<div class="col-lg-5">
									<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
										<img class="w-100" src="../assets/images/_new/safe-system.png" alt="About Images">
									</div>
								</div>
							</div>
            </div>
        </div>

				<div class="rn-service-area rn-section-gap bg-light">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="解决方案"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15">
							<div class="col-lg-6 col-md-6 col-sm-6 col-12">
								<div class="service service__style--2 icon-circle-style text-center"
										 data-aos="fade-up"
										 data-aos-delay="70">
									<div class="image">
										<img src="../assets/images/_new/solution1.png">
									</div>
									<div class="content">
										<h4 class="title w-600">
											数据泄漏防护解决方案
										</h4>
										<p class="description b1 color-gray mb--0">
											由于电子信息传播途径广、传播速度快、拷贝/复制操作无痕迹及易携带等特性，普通的网络边界防护措施已无法有效控制有内部人员参与的泄密事件的发生。所以如何保证企业内网中的核心数据资产安全是关系到企业生存发展乃至国家安全的重要问题。
										</p>
									</div>
								</div>
							</div>
							<div class="col-lg-6 col-md-6 col-sm-6 col-12">
								<div class="service service__style--2 text-center"
										 data-aos="fade-up"
										 data-aos-delay="70">
									<div class="image">
										<img src="../assets/images/_new/solution2.png">
									</div>
									<div class="content">
										<h4 class="title w-600">
											数据库安全综合解决方案
										</h4>
										<p class="description b1 color-gray mb--0">
											随着计算机和网络技术发展，信息系统的应用越来越广泛。数据库做为信息技术的核心和基础，承载着越来越多的关键业务系统，渐渐成为商业和公共安全中最具有战略性的资产，数据库的安全稳定运行也直接决定着业务系统能否正常使用。围绕数据库的业务系统安全隐患如何得到有效解决，一直以来是IT治理人员和DBA们关注的焦点。
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="rn-service-area rn-section-gap">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="产品优势"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15 service-wrapper pt--40" style="--bs-gutter-y: 8rem;">
							<div class="col-lg-3 col-md-6 col-sm-6 col-12"
									 v-for="(service, index) in productList"
									 :key=index>
								<div :class="`service service__style--1 icon-circle-style text-center`"
										 data-aos="fade-up"
										 data-aos-delay="70">
									<div class="icon">
										<img class="size40" :src="service.img" />
									</div>
									<div class="content">
										<h4 class="title w-600">
											{{ service.title }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle.vue'
    export default {
        name: 'DataSecurity',
        components: {
            SectionTitle,
            Layout
        },
        data() {
            return {
							productList: [
								{
									img: require(`@/assets/images/_new/a1.png`),
									title: '访问权限精细控制'
								},
								{
									img: require(`@/assets/images/_new/a2.png`),
									title: '风险行为控制审计'
								},
								{
									img: require(`@/assets/images/_new/a3.png`),
									title: '支持语句黑白名单'
								},
								{
									img: require(`@/assets/images/_new/a4.png`),
									title: 'SQL注入行为检测阻断'
								},
								{
									img: require(`@/assets/images/_new/a5.png`),
									title: '防止敏感数据泄漏篡改'
								},
								{
									img: require(`@/assets/images/_new/a6.png`),
									title: '平台高度兼容性'
								},
								{
									img: require(`@/assets/images/_new/a7.png`),
									title: '部署灵活'
								},
								{
									img: require(`@/assets/images/_new/a8.png`),
									title: '性能高效'
								},
							]
            }
        }
    }
</script>
