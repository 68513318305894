import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home.vue";
import DataSecurity from '@/views/DataSecurity.vue'
import SecurityOperation from '@/views/SecurityOperation.vue'
import CloudSecurity from '@/views/CloudSecurity.vue'
import About from '../views/About.vue'
import ErrorPage from '../views/404'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页 - 驰安未来',
        },
    },
    {
        path: '/data-security',
        name: 'DataSecurity',
        component: DataSecurity,
        meta: {
            title: '数据安全 - 驰安未来',
        },
    },
    {
        path: '/security-operation',
        name: 'SecurityOperation',
        component: SecurityOperation,
        meta: {
            title: '安全运营 - 驰安未来',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '关于我们 - 驰安未来',
        },
    },
    {
        path: '/cloud-security',
        name: 'CloudSecurity',
        component: CloudSecurity,
        meta: {
            title: '云安全 - 驰安未来',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '404 - 驰安未来',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
