<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="copyright-right text-center">
                        <p class="copyright-text">©{{ new Date().getFullYear() }} 江苏驰安未来信息技术有限公司  <a class="text-dark-emphasis" href="https://beian.miit.gov.cn/">苏ICP备2024096624号-1</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>
