<template>
    <Layout>
				<div class="slider-area slider-style-6 shape-left inheader-not-transparent height-500">
					<div class="container">
						<div class="row row--30 align-items-center">
							<div class="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
								<div class="inner text-left">
									<h1 class="title color-white" data-aos="fade-up" data-aos-delay="100">
										企业愿景
									</h1>
									<p class="description color-white" data-aos="fade-up" data-aos-delay="150">
										我们致力于成为一家领先的安全运营公司；<br/> 做企业安全服务行业的开创者和领军者。
									</p>
								</div>
							</div>
							<div class="shape-image w-auto">
								<img src="../assets/images/white-shape.svg" alt="Banner Images"/>
							</div>
						</div>
					</div>
				</div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            title="公司介绍"
                            data-aos="fade-up"
                        />
                    </div>
									<div class="col-lg-12">
										<div class="description" data-aos="fade-up">
											<div class="description-line">
												江苏驰安未来信息技术有限公司是一家专注于安全运营工具链研发和落地实践的初创企业，核心团队技术过硬、创新务实，坚持通过自主创新，努力提升公司核心竞争力。
											</div>
											<div class="description-line">
												通过驰安大数据防护系统、驰安安全验证平台、驰安DDoS高防御IP防御服务系统，协助甲方安全团队建立精细化安全运营能力，不断提升安全运营效率。
											</div>
											<div class="description-line">
												我们致力于成为一家领先的安全运营公司，做企业安全服务行业的开创者和领军者。
											</div>
										</div>
									</div>
                </div>
            </div>
        </div>

			<div class="rwt-tab-area rn-section-gap bg-light">
				<div class="container">
					<div class="row mb--50" data-aos="fade-up" data-aos-delay="100">
						<div class="col-lg-12 text-center">
							<h3>联系我们</h3>
						</div>
					</div>
					<div class="row row--20" data-aos="fade-up" data-aos-delay="200">
						<div class="col-lg-12">
							<baidu-map
								class="bm-view"
								:center="center"
								:zoom="15"
								@ready="handler"
							>
								<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
								<bm-marker :position="center" :dragging="true" @click="show = true">
									<bm-info-window :show="show" @close="show = false" @open="show = true">
										<h4>江苏驰安未来信息技术有限公司</h4>
										<div>地址：江苏省南京市江宁区龙眠大道768号T2栋9层901-3室（江宁高新园）</div>
									</bm-info-window>
								</bm-marker>
							</baidu-map>
						</div>
					</div>
					<div>
					</div>
				</div>
			</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import Separator from '../components/elements/separator/Separator.vue'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle.vue'

    export default {
        name: 'About',
        components: {SectionTitle, Separator, Layout},
				methods: {
					handler ({BMap, map}) {
						this.center.lng = 118.90
						this.center.lat = 31.91
					}
				},
				data() {
					return {
						center: {lng:0, lat: 0},
						show: true
					}
				}
    }
</script>
<style>
.bm-view {
	width: 100%;
	height: 480px;
}
</style>
