<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-6 shape-left inheader-not-transparent height-500">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title color-white" data-aos="fade-up" data-aos-delay="100">
															安全       稳定
                            </h1>
                            <p class="description color-white" data-aos="fade-up" data-aos-delay="150">
															大宽带多线BGP节点集群，资源保障充足<br>
															多线分布式防御系统<br>
															不用SDK实现盾的防CC效果
														</p>
                        </div>
                    </div>
                    <div class="shape-image w-auto">
                        <img src="../assets/images/white-shape.svg" alt="Banner Images"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
							<div class="row row--30 align-items-center">
								<div class="col-lg-7 mt_md--40 mt_sm--40">
									<div class="content">
										<div class="section-title">
											<h2 class="title mt--10" data-aos="fade-up" data-aos-delay="310">驰安DDoS高防御IP防御服务系统</h2>
											<p data-aos="fade-up" data-aos-delay="310">
												<div class="description" data-aos="fade-up">
													DDOS高防IP是针对互联网服务器（包括公有云、IDC主机）在遭受大流量DDOS攻击后导致服务不可用的情况下，推出的付费服务，用户可通过配置高防IP，将攻击流量引流到高防IP，确保源站的稳定可靠性。
												</div>
											</p>
										</div>
									</div>
								</div>
								<div class="col-lg-5">
									<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
										<img class="w-100" src="../assets/images/_new/ss2.png" alt="About Images">
									</div>
								</div>
							</div>
            </div>
        </div>

				<div class="rn-service-area rn-section-gap bg-light">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="解决方案"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15 service-wrapper">
							<div class="col-md-6 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud0.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													网络游戏
												</h4>
												<p class="description b1 color-gray mb--0">
													网络游戏一直是DDoS/CC攻击的重灾区，并且也是对防护效果、网络质量要求最高的行业，共启高防通过大带宽BGP防护网络，多机房多线路同步防御，肉鸡行为以及无SDK加密水印三重防御CC的技术，为客户提供高质量的防御方案。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud1.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													智慧城市
												</h4>
												<p class="description b1 color-gray mb--0">
													智慧城市项目在落地过程中经常会遇到竞争对手的恶意攻击，而车牌识别、缴费等部署在公网对外提供服务的接口，是黑客攻击的重点，共启高防通过业务行为分析、肉鸡地域检测等方式为智慧城市项目提供完善的防护方案。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud2.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													电商平台
												</h4>
												<p class="description b1 color-gray mb--0">
													电商APP、小程序、公众号等业务的支付、搜索等接口经常会面临应用层CC攻击，共启高防定制化的肉鸡行为检测，攻击报文特征深度检验技术为电商平台提供了强有力的防护方案。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/cloud3.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													互联网金融
												</h4>
												<p class="description b1 color-gray mb--0">
													互联网金融由于其行业特殊性，其对高防平台的稳定性要求极高，共启高防多机房联动共同防御方式，保证单一机房故障时业务不受影响，为互金提供坚若磐石的防御方案。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="rn-service-area rn-section-gap">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="产品优势"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15 service-wrapper pt--40" style="--bs-gutter-y: 8rem;">
							<div class="col-lg-3 col-md-6 col-sm-6 col-12"
									 v-for="(service, index) in productList"
									 :key=index>
								<div :class="`service service__style--1 icon-circle-style text-center`"
										 data-aos="fade-up"
										 data-aos-delay="70">
									<div class="icon">
										<img class="size40" :src="service.img" />
									</div>
									<div class="content">
										<h4 class="title w-600">
											{{ service.title }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle.vue'

    export default {
        name: 'CloudSecurity',
        components: {
            SectionTitle,
            Layout
        },
        data() {
            return {
							productList: [
								{
									img: require(`@/assets/images/_new/ca1.png`),
									title: '高效清洗设备',
								},
								{
									img: require(`@/assets/images/_new/ca2.png`),
									title: '多机房分布式抗DDOS',
								},
								{
									img: require(`@/assets/images/_new/ca3.png`),
									title: '超大BGP多线防护',
								},
								{
									img: require(`@/assets/images/_new/ca4.png`),
									title: '定制化CC防御能力',
								},
								{
									img: require(`@/assets/images/_new/ca5.png`),
									title: '虚拟机架构',
								},
								{
									img: require(`@/assets/images/_new/ca6.png`),
									title: '50毫秒快速响应',
								},
								{
									img: require(`@/assets/images/_new/ca7.png`),
									title: '开放API',
								},
								{
									img: require(`@/assets/images/_new/ca8.png`),
									title: '丰富数据与策略管控',
								},
							],
            }
        }
    }
</script>
