<template>
    <Layout>
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-6 shape-left inheader-not-transparent height-500">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-12 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title color-white" data-aos="fade-up" data-aos-delay="100">
															自主创新   技术过硬
                            </h1>
                            <p class="description color-white" data-aos="fade-up" data-aos-delay="150">
															安全有效性验证的领军者<br>
															全面、自动、高效、可见的安全有效性验证和度量
														</p>
                        </div>
                    </div>
                    <div class="shape-image w-auto">
                        <img src="../assets/images/white-shape.svg" alt="Banner Images"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
							<div class="row row--30 align-items-center">
								<div class="col-lg-7 mt_md--40 mt_sm--40">
									<div class="content">
										<div class="section-title">
											<h2 class="title mt--10" data-aos="fade-up" data-aos-delay="310">驰安安全验证平台</h2>
											<p data-aos="fade-up" data-aos-delay="310">
												<div class="description" data-aos="fade-up">
													通过自动化的闭环手段、最新最全的验证用例，持续对企业纵深防御体系的有效性进行验证，并提供高度可见的安全度量，帮助企业提高运营效率、发现和解决安全失效点问题、全面提升安全运营能力。
												</div>
											</p>
										</div>
									</div>
								</div>
								<div class="col-lg-5">
									<div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
										<img class="w-100" src="../assets/images/_new/ss1.png" alt="About Images">
									</div>
								</div>
							</div>
            </div>
        </div>

				<div class="rn-service-area rn-section-gap bg-light">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="应用场景"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15 service-wrapper pt--40">
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op1.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													安全自动化巡检
												</h4>
												<p class="description b1 color-gray mb--0">
													通过自动化和常态化的周期性持续验证，帮助用户先于攻击者发现安全策略失效点，缩短失效点存在的时间周期，及时解决，降低失效导致的攻击风险。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op2.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													精细化安全策略运营
												</h4>
												<p class="description b1 color-gray mb--0">
													提供验证失效的攻击手法和攻击工具细节，辅助企业安全团队改进安全策略，建立精细化安全运营能力。特别的，提供部分防护策略配置的行业最佳实践。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-6 col-sm-6 col-12">
								<div data-aos="fade-up" data-aos-delay="70">
									<div class="service service__style--2 text-center">
										<div class="inner">
											<div class="image">
												<img src="../assets/images/_new/op3.png" alt="Service Images"/>
											</div>
											<div class="content">
												<h4 class="title">
													企业防护能力检验
												</h4>
												<p class="description b1 color-gray mb--0">
													出现最新的漏洞利用、攻击工具和攻击手法时，帮助企业安全负责人想第一时间获悉情报，基于现有的防御体系拦截和告警情况。对于不能拦截和告警的防御措施，再针对性的制定方案，如等待安全设备厂商更新规则，或是其他处置技术方案。
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="rn-service-area rn-section-gap">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<SectionTitle
									text-align="center"
									title="产品优势"
									data-aos="fade-up"
								/>
							</div>
						</div>
						<div class="row row--15 service-wrapper pt--40" style="--bs-gutter-y: 8rem;">
							<div class="col-lg-4 col-md-4 col-sm-12 col-12"
									 v-for="(service, index) in productList"
									 :key=index>
								<div :class="`service service__style--1 icon-circle-style text-center`"
										 data-aos="fade-up"
										 data-aos-delay="70">
									<div class="icon">
										<img class="size40" :src="service.img" />
									</div>
									<div class="content">
										<h4 class="title w-600">
											{{ service.title }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout.vue'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle.vue'

    export default {
        name: 'SecurityOperation',
        components: {
            SectionTitle,
            Layout
        },
        data() {
            return {
							productList: [
								{
									img: require(`@/assets/images/_new/oa1.png`),
									title: '深耕安全运营',
								},
								{
									img: require(`@/assets/images/_new/oa2.png`),
									title: '专注用户洞察',
								},
								{
									img: require(`@/assets/images/_new/oa3.png`),
									title: '攻防技术积累',
								},
								{
									img: require(`@/assets/images/_new/oa4.png`),
									title: '产品化',
								},
								{
									img: require(`@/assets/images/_new/oa5.png`),
									title: '商业化',
								},
								{
									img: require(`@/assets/images/_new/oa6.png`),
									title: '高成熟度',
								},
							],
            }
        }
    }
</script>
