<template>
    <ul class="mainmenu">
			<li><router-link to="/">首页</router-link></li>
			<li><router-link to="/data-security">数据安全</router-link></li>
			<li><router-link to="/security-operation">安全运营</router-link></li>
			<li><router-link to="/cloud-security">云安全</router-link></li>
			<li><router-link to="/about">关于我们</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon}
    }
</script>
